import { InfoSectionContainer } from './style'
import InfoCard from './components/InfoCard'
import BenefitsSection from './BenefitsSection'
import InfoMobile from './components/InfoMobile'
import MobileBenefitsSection from './MobileBenefitsSection'
import { FadeIn } from 'shared/animations'

const InfoSection = () => {
  return (
    <>
      <InfoSectionContainer id='About-us'>
        <FadeIn>
          <InfoMobile />
        </FadeIn>
        <FadeIn>
          <InfoCard />
        </FadeIn>
      </InfoSectionContainer>
      <BenefitsSection />
      <FadeIn>
        <MobileBenefitsSection />
      </FadeIn>
    </>
  )
}

export default InfoSection
