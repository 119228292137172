import Link from 'next/link'
import Image from 'next/image'

import { FlexCol } from 'styles/styled'
import ShipOne from 'public/assets/landing/ship.png'

import * as Styles from './style'
import { FadeLeft, FadeRight } from 'shared/animations'
import { VTRCONNECT_LINK } from 'shared/constants'

export const INFO_DATA = [
  {
    title: 'About the vtr platform',
    info: 'VTR Connect is a new online platform that aims to introduce Blockchain Technology, Automation, and Gamification to the Commercial Real Estate Industry. VTR was created to help small business owners that are underrepresented in the real estate industry. To empower small business owners to compete with large enterprise with affordable networking and technology products.',
    image: ShipOne,
  },
  {
    title: 'About the ART ',
    info: (
      <>
        A NFT art collection of the “Original Starling” created by Courtney Ogle
        transcended through generative AI to produce 5,000 unique art pieces
        that doubles as a functional Lifetime Membership Token to access
        the&nbsp;
        <Link
          href={VTRCONNECT_LINK}
          target='_blank'
          rel='noopener noreferrer'
          className='highlight'
        >
          www.VTRConnect.com
        </Link>
        &nbsp;Real Estate Platform. The Starling was chosen to be the icon of
        the VTR Connect Platform specifically to express the powerful feelings
        and motions of separation, alignment, cohesion, and emergence as seen
        and felt by entrepreneurs and startup teams on their journey building
        something new. The Original Starling NFT murmuration, represents this
        journey with art and utility.
      </>
    ),
    image: ShipOne,
  },
  {
    title: 'About the vtr NFTs',
    info: 'This is the first functional utility mint of the “Original Starling” NFT. The following are the current list of functions of owning this NFT. Buying a VTR “Original Starling” costs 1.0 ETH. There are no price tiers. All VTR Membership costs the same for everyone.',
    image: ShipOne,
  },
]

const InfoCard = () => {
  return (
    <Styles.InfoCardContainer>
      <FlexCol gap='140px'>
        {INFO_DATA?.map((data, index) => (
          <Styles.CardContainer key={data.title}>
            <FadeLeft>
              <Styles.ProgressWrap>
                <Styles.ProgressCont gap='16px' alignItems='stretch'>
                  <Styles.ProgressDiv
                    isActive={index === 0}
                  ></Styles.ProgressDiv>
                  <Styles.ProgressDiv
                    isActive={index === 1}
                  ></Styles.ProgressDiv>
                  <Styles.ProgressDiv
                    isActive={index === 2}
                  ></Styles.ProgressDiv>
                </Styles.ProgressCont>

                <Styles.TextContainer gap='32px'>
                  <FlexCol gap='24px'>
                    <FlexCol gap='32px'>
                      <p className='title'>{data?.title}</p>
                      <p>{data.info}</p>
                    </FlexCol>

                    {data?.title?.toLowerCase().includes('nfts') ? (
                      <Styles.ListContainer>
                        <ul style={{ marginLeft: '24px' }}>
                          <li style={{ marginBottom: '16px' }}>
                            Enjoy Free access to&nbsp;
                            <Link
                              href={VTRCONNECT_LINK}
                              target='_blank'
                              rel='noopener noreferrer'
                              className='highlight'
                            >
                              www.vtrconnect.com
                            </Link>
                          </li>
                          <li style={{ marginBottom: '16px' }}>
                            Access to the WEB3 VTR Mission/Rewards System
                          </li>
                          <li style={{ marginBottom: '16px' }}>
                            Randomized Airdrops for major Commercial Real Estate
                            Industry Event Tickets
                          </li>
                        </ul>
                      </Styles.ListContainer>
                    ) : null}
                  </FlexCol>
                </Styles.TextContainer>
              </Styles.ProgressWrap>
            </FadeLeft>

            <FadeRight>
              <Styles.ImageContainer>
                <Image src={data?.image} alt='space-icon' fetchPriority='low' />
              </Styles.ImageContainer>
            </FadeRight>
          </Styles.CardContainer>
        ))}
      </FlexCol>
    </Styles.InfoCardContainer>
  )
}

export default InfoCard
