import Link from 'next/link'
import Slider from 'react-slick'

import * as Styles from './style'
import { INFO_DATA } from './InfoCard'
import { FlexCol } from 'styles/styled'
import { VTRCONNECT_LINK } from 'shared/constants'

const settings = {
  dots: true,
  infinite: true,
  arrows: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 10000,
  pauseOnHover: true,
  cssEase: 'linear',
  customPaging: () => <div className='tabs' />,
}

const InfoMobile = () => {
  return (
    <Styles.MobileInfoCont>
      <Slider {...settings}>
        {INFO_DATA?.map((data, index) => (
          <Styles.TextContainer gap='32px' key={index}>
            <FlexCol gap='24px'>
              <FlexCol gap='32px'>
                <p className='title'>{data?.title}</p>
                <p>{data.info}</p>
              </FlexCol>

              {data?.title?.toLowerCase().includes('nfts') ? (
                <Styles.ListContainer>
                  <ul style={{ marginLeft: '24px' }}>
                    <li style={{ marginBottom: '16px' }}>
                      Enjoy Free access to{' '}
                      <Link
                        href={VTRCONNECT_LINK}
                        className='hyper-link'
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        www.vtrconnect.com
                      </Link>
                    </li>
                    <li style={{ marginBottom: '16px' }}>
                      Access to the WEB3 VTR Mission/Rewards System
                    </li>
                    <li style={{ marginBottom: '16px' }}>
                      Randomized Airdrops for major Commercial Real Estate
                      Industry Event Tickets
                    </li>
                  </ul>
                </Styles.ListContainer>
              ) : null}
            </FlexCol>
          </Styles.TextContainer>
        ))}
      </Slider>
    </Styles.MobileInfoCont>
  )
}

export default InfoMobile
